// react section
import {Fragment} from "react";

export const Error = () => {
    return (
        <Fragment>
            <section id="error">
                {/* 404 content */}
                <div className="container h-100">
                    {/* start row */}
                    <div className="row justify-content-center align-items-center h-100">
                        {/* collation */}
                        <div className="col-md-5">
                            {/* start card */}
                            <div className="card worker-card card-body border-0">
                                {/* error video */}
                                <div className="error-video">
                                    <img alt="error" src={require('./../../../../original/image/404.png')}
                                         className="img-fluid animated-img"/>
                                </div>
                                {/* end error video */}
                                {/* error body */}
                                <div className="error-body">
                                    <h5 className="card-title fw-bold text-center">صفحه مد نظر پیدا نشد</h5>
                                </div>
                                {/* end error body */}
                            </div>
                            {/* end card */}
                        </div>
                        {/* end collation */}
                    </div>
                    {/* end row */}
                </div>
                {/* end 404 content */}
            </section>
        </Fragment>
    )
}