// style
import '../css/home.css'
// react section
import {Fragment, lazy, useEffect, useState} from "react";
// image
import banner from "./../../../../original/image/banner.webp"
// Swiper
import {Swiper, SwiperSlide} from 'swiper/react';
// import required modules
import {Pagination, Navigation} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination"
import "swiper/css/navigation"
// link
import {Link} from "react-router-dom";
import {routes} from "../../../../original/route/route";
// axios
import axios from "axios";
// cookie
import Cookies from "js-cookie";
// icon
import {IconX} from "@tabler/icons-react";
// spinner
import {Spinner} from "../../../../original/js/spinner";
// functions
import {getGallery} from "../../../../original/js/gallery";
import {getLike} from "../../../../original/js/like";
import {userLike} from "../../../../original/js/userLike";
// image load
import LazyLoad from 'react-lazy-load';
// component
import {Seo} from "../../../layout/seo/seo";
import {Banner} from "../../../layout/banner/page/banner";

const Item = lazy(() => import('./../../gallery/page/child/item').then(module => ({default: module.Item})));
const MenuItem = lazy(() => import('./../../menu/page/child/weakItem').then(module => ({default: module.MenuItem})));


export const Home = () => {
    const [item, setItem] = useState([{}]);
    const [weakly, setWeakly] = useState([{}]);
    const [userLikeGallery, setUserLike] = useState([{}]);
    const [userName, setUsrName] = useState('')
    // token
    // get user info
    let token = Cookies.get('emarat_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    useEffect(() => {
        getGallery(setItem);
        getLike(setUserLike);
        axios.get(`${routes[5].address}suggestions`)
            .then((response) => {
                setWeakly(response.data.data)
            })
            .catch((error) => {
                if (error.response) {
                }
            })
        setUsrName(localStorage.getItem('user_name'));
    }, []);
    const sendMusic = async (btn) => {
        Spinner(btn)
        let submit = document.getElementById(btn);
        await axios.post(`${routes[5].address}suggestion`, {
            user_name: document.getElementById('user_name').value,
            music_name: document.getElementById('user_music').value
        }, config)
            .then(() => {
                submit.innerHTML = "تبریک !";
                submit.disabled = false;
                document.getElementById('user-alert').innerHTML = null;
                document.getElementById('user-music-alert').innerHTML = null;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.music_name) {
                        document.getElementById('user-music-alert').innerHTML = error.response.data.message.music_name
                    } else {
                        document.getElementById('user-music-alert').innerHTML = null;
                    }
                    if (error.response.data.message.user_name) {
                        document.getElementById('user-alert').innerHTML = error.response.data.message.user_name;
                    } else {
                        document.getElementById('user-alert').innerHTML = null;
                    }
                    submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    submit.disabled = false;
                }
            })
    }
    return (
        <Fragment>
            {/* do seo for pages */}
            <Seo
                title="عمارت سران | خانه"
                description="کافه رستوران عمارت سران بهترین انتخاب برای گذراندان اوقات شریفتان در فضایی زیبا به همراه بهترین متریال ها و لذیذ ترین غدا ها که مبنای سعیش جذب رضایت کامل مشتری و عزیزان است !"
                keywords="عمارت سران،کافه رستوران،کافه،رستوران،غذا،دکور،منو،گالری"
                type="webapp"
                name="progogram"
            />
            {/* home */}
            <section id="home" className="container pb-5">
                <Banner image={banner}
                        title={'عمارت سران'}
                        description={
                            `عمارت سران تنها یک کافه رستوران نیست بلکه خانه شماست !
                            مکانی بی نظیر برای دعوت از کسانی که دوستشان داریم...`
                        }
                        link={routes[1].address}
                        link_text={'درباره ما'}/>
                {/* branch */}
                <div id="branch-home" className="mt-5">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-up" data-aos-duration="2000">
                            <div className="card card-body branch-item-card border-0 shadow">
                                <div className="row align-items-center">
                                    <div className="col-md-4 text-center">
                                        <LazyLoad className="text-center">
                                            <img loading={'lazy'} className="img-fluid text-center card-image-des"
                                                 alt="coffe"
                                                 src={require('./../../../../original/image/two-paper.webp')}/>
                                        </LazyLoad>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-texts me-md-4 mt-md-0 mt-3">
                                            <div className="title text-md-end text-center">
                                                <p className="h5 text-md-end text-center fw-bold card-title">
                                                    کافه عمارت سران
                                                </p>
                                            </div>
                                            <p className="card-text mt-3">
                                                کافه عمارت سران بهترین انتخاب شما برای سپرس کردن اوقات شریفتان در
                                                کنار
                                                میل
                                                کردن بهترین آیتم ها و متریال های حال حاضر !
                                            </p>
                                            <div className="d-flex justify-content-end">
                                                <Link to='/menu/coffee' className="btn btn-outline-site py-2 px-4">
                                                    منو کافه
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-5" data-aos="fade-down" data-aos-duration="2000">
                            <div className="card card-body branch-item-card border-0 shadow">
                                <div className="row align-items-center">
                                    <div className="col-md-8 order-md-0 order-1">
                                        <div className="card-texts d-block ms-md-4">
                                            <div className="title text-md-end text-center">
                                                <p className="h5 fw-bold card-title text-center text-md-end">
                                                    رستوران عمارت سران
                                                </p>
                                            </div>
                                            <p className="card-text mt-3">
                                                با عزیزان خود بهترین و لذیذ ترین غذاها را در محیط بسیار زیبا و گیرای
                                                عمارت
                                                سران تجربه کنید و با دوستان و آشنایان خود به اشتراک بگذارید !
                                            </p>
                                            <div className="d-flex justify-content-start">
                                                <Link to='/menu/restaurant' className="btn btn-outline-site py-2">
                                                    منو رستوران
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center order-md-1 order-0">
                                        <LazyLoad className="text-center">
                                            <img loading={'lazy'} className="img-fluid card-image-des" alt="coffe"
                                                 src={require('./../../../../original/image/6085705_prev_ui.webp')}/>
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* gallery */}
                <div id="gallery-home"
                     className="mt-5 text-md-end text-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="h4 mb-0 text-site fw-bold text-md-end text-center">گالری شما</span>
                        <Link to={routes[2].address} className="btn btn-outline-site">شرکت در گالری</Link>
                    </div>
                    <div className="row mt-4">
                        <Swiper className="pb-5"
                                spaceBetween={20}
                                slidesPerView={4}
                                navigation={{
                                    prevEl: '.next',
                                    nextEl: '.prev',
                                }}
                                breakpoints={{
                                    200: {
                                        // width: 576,
                                        slidesPerView: 1.25,
                                    },
                                    768: {
                                        // width: 768,
                                        slidesPerView: 2.5,
                                    },
                                    1000: {
                                        // width: 1000
                                        slidesPerView: 3.5,
                                    },
                                    1200: {
                                        // width: 1200
                                        slidesPerView: 4,
                                    },
                                }}
                                pagination={{clickable: true}}
                                modules={[Pagination, Navigation]}
                        >
                            {item.map((photo, index) => (
                                <SwiperSlide key={index}>
                                    <Item userLike={() => userLike(photo.id, setItem, setUserLike)}
                                          image={photo.image}
                                          like={photo.likes}
                                          time={photo.created_at}
                                          name={photo.name}
                                          id={photo.id}
                                          gallery={item}
                                          likeGallery={userLikeGallery}/>
                                </SwiperSlide>
                            ))}
                            <div className="prev">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                     viewBox="0 0 34 34" fill="none">
                                    <path
                                        d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34Z"
                                        fill="#C07B36" fillOpacity="0.61"/>
                                    <path d="M19.142 23.001L13.158 17L19.142 10.999" stroke="#FEFEFE"
                                          strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34"
                                     viewBox="0 0 34 34" fill="none">
                                    <path
                                        d="M16.8334 33.8333C7.53661 33.8333 6.10352e-05 26.2968 6.10352e-05 17C6.10352e-05 7.70317 7.53661 0.166626 16.8334 0.166626C26.1302 0.166626 33.6667 7.70317 33.6667 17C33.6667 26.2968 26.1302 33.8333 16.8334 33.8333Z"
                                        fill="#C07B36" fillOpacity="0.61"/>
                                    <path d="M14.7124 22.9422L20.6377 17L14.7124 11.0579" stroke="#FEFEFE"
                                          strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </Swiper>
                    </div>
                </div>
                {/* weakly */}
                <div id="weakly-part"
                     className="mt-5 text-md-end text-center">
                    <LazyLoad>
                        <img loading={'lazy'} alt="divider" src={require('./../../../../original/icon/Asset-12 2.png')}
                             className="img-fluid w-100"/>
                    </LazyLoad>
                    <h4 className="text-site fw-bold mt-5 text-md-end text-center">پیشنهاد هفته</h4>
                    <Swiper className="pb-5 mt-4"
                            spaceBetween={20}
                            slidesPerView={4}
                            navigation={{
                                prevEl: '.next',
                                nextEl: '.prev',
                            }}
                            breakpoints={{
                                200: {
                                    // width: 576,
                                    slidesPerView: 1.25,
                                },
                                768: {
                                    // width: 768,
                                    slidesPerView: 2.5,
                                },
                                1000: {
                                    // width: 1000
                                    slidesPerView: 3.5,
                                },
                                1200: {
                                    // width: 1200
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={{clickable: true}}
                            modules={[Pagination, Navigation]}
                    >
                        {weakly.map((item, index) => (
                            <SwiperSlide key={index}>
                                <MenuItem name={item.name}
                                          description={item.description}
                                          discount={item.discount_percentage ? item.discount_percentage : null}
                                          price={item.discount_price ? item.discount_price : item.price}
                                          image={item.image}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {/* description */}
                <div id="description-part" className="mt-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-end" data-aos="fade-up" data-aos-duration="2000">
                            <LazyLoad>
                                <img loading={'lazy'} id="description-img" alt="description"
                                     src={require('./../../../../original/image/pizza.png')}
                                     className="img-fluid description-img"/>
                            </LazyLoad>
                        </div>
                        <div className="col-md-6 text-md-end text-center mt-md-0 mt-4" data-aos="fade-down"
                             data-aos-duration="2000">
                            <h4 className="card-title text-md-end text-center fw-bold">
                                ما میزبان لحظات شیرین شما هستیم !
                            </h4>
                            <p className="card-text mt-3">
                                طعم بهشت، در کافه رستوران عمارت سران ، جایی که طعم و خاطره به هم می‌رسند و
                                لحظات ناب و خاطرات خوش در آن رقم می‌خورد.
                            </p>
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-outline-site" data-bs-toggle='modal'
                                        data-bs-target={'#music-modal'}>
                                    پیشنهاد موزیک
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* menu description */}
                <div id="menu-description" className="mt-5">
                    <div className="row align-items-center">
                        <div data-aos="fade-down" data-aos-duration="2000"
                             className="col-lg-7 ps-md-5 col-md-6 text-md-end text-center order-md-0 order-1 mt-md-0 mt-4">
                            <h1 className="fw-bold card-title text-md-end text-center">عمارت سران</h1>
                            <LazyLoad className="text-md-end text-center">
                                <img loading={'lazy'} alt="asset"
                                     src={require('./../../../../original/icon/Asset-12 1.png')}
                                     className="img-fluid divider mt-2"/>
                            </LazyLoad>
                            <p className="card-text mt-2">
                                کافه رستوران عمارت سران با فضایی دلنشین و دکوراسیونی جذاب، مکانی ایده‌آل برای دورهمی‌های
                                دوستانه و خانوادگی شماست.
                                منوی متنوع ما شامل انواع غذاهای ایرانی، فرنگی، دریایی و فست فود، با بهترین مواد اولیه و
                                توسط سرآشپزهای مجرب تهیه شده تا هر سلیقه‌ای را راضی کند.
                                علاوه بر غذاهای لذیذ، می‌توانید از انواع نوشیدنی‌های گرم و سرد، کیک و دسرهای خوشمزه در
                                کافه ما لذت ببرید.
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000"
                             className="col-lg-5 col-md-6 order-md-1 order-0">
                            <div className="row">
                                <div className="col-6 mb-4">
                                    <LazyLoad>
                                        <img loading={'lazy'} alt="menu_item" className="img-fluid rounded rounded-4"
                                             src={require('../../../../original/image/food-menu-1.webp')}/>
                                    </LazyLoad>
                                </div>
                                <div className="col-6 mb-4">
                                    <LazyLoad>
                                        <img loading={'lazy'} alt="menu_item" className="img-fluid rounded rounded-4"
                                             src={require('../../../../original/image/food-menu-2.webp')}/>
                                    </LazyLoad>
                                </div>
                                <div className="col-6">
                                    <LazyLoad>
                                        <img loading={'lazy'} alt="menu_item" className="img-fluid rounded rounded-4"
                                             src={require('../../../../original/image/food-menu-3.webp')}/>
                                    </LazyLoad>
                                </div>
                                <div className="col-6">
                                    <LazyLoad>
                                        <img loading={'lazy'} alt="menu_item" className="img-fluid rounded rounded-4"
                                             src={require('../../../../original/image/food-menu-4.webp')}/>
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="music-modal" className="modal fade">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-between">
                                <span className="card-title mb-0 h6 fw-bold">پیشنهاد موزیک</span>
                                <IconX color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                            </div>
                            <div className="modal-body">
                                <div className="form text-center">
                                    <fieldset className="form-floating mt-4">
                                        <input type="text"
                                               className="form-control"
                                               name="user_name"
                                               placeholder={'نام یا آیدی اینستاگرام'}
                                               id="user_name"
                                               defaultValue={userName}/>
                                        <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                    </fieldset>
                                    <p className="text-site mt-2 font-12 mb-0">
                                        <span id="user-alert"></span>
                                    </p>
                                    <fieldset className="form-floating mt-4">
                                        <input type="text"
                                               className="form-control"
                                               name="user_music"
                                               placeholder={'لینک یا نام موزیک پیشنهادی'}
                                               id="user_music"/>
                                        <label className="form-label font-14">لینک یا نام موزیک پیشنهادی</label>
                                    </fieldset>
                                    <p className="text-site mt-2 font-12 mb-0">
                                        <span id="user-music-alert"></span>
                                    </p>
                                    <button id="send-music"
                                            onClick={() => sendMusic('send-music')}
                                            className="btn btn-site w-100 my-4">
                                        ارسال پیشنهاد
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}