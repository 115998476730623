import axios from "axios";
import {routes} from "../route/route";

export const getGallery = (setItem) => {
    axios.get(`${routes[5].address}top-galleries`)
        .then((response) => {
            let galleries = response.data.data;
            galleries = galleries.filter((item) => item.approved !== "تایید نشده")
            setItem(galleries);
        })
        .catch((error) => {
            if (error.response) {
            }
        })
}


