import '../../css/google.css';
// hook
import {useEffect} from "react";
// dom
import {useLocation} from "react-router-dom";
// axios
import axios from "axios";
// link
import {routes} from "../../../../../original/route/route";
// cookie
import Cookies from 'js-cookie';


export const GoogleCallback = () => {
    const location = useLocation();
    useEffect(() => {
        axios.get(`${routes[7].get_user}${location.search}`)
            .then((response) => {
                localStorage.setItem('user_name', response.data.user.name);
                localStorage.setItem('email', response.data.user.email);
                Cookies.set('emarat_token', response.data.token, {expires: 7})
                setTimeout(() => {
                    window.open(routes[0].address, '_self');
                }, 2000)
            })
            .catch((error) => {
                if(error.response){}
            })
    }, []);
    return (
        <section id="google" className="container">
            <div className="message">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10"
                            cx="65.1" cy="65.1" r="62.1"/>
                    <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6"
                              strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                </svg>
                <p className="success">ثبت نام / ورود با موفقیت انجام شد !</p>
            </div>
        </section>
    )
}