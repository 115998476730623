// react section
import {Fragment, lazy, useEffect, useState} from "react";
// seo
import {Seo} from "../../../../layout/seo/seo";
// icon
import {IconAlertCircle, IconX} from "@tabler/icons-react";
// cookie
import Cookies from "js-cookie";
// axios
import axios from "axios";
// link
import {routes} from "../../../../../original/route/route";
// alert
import Swal from "sweetalert2";
// uploader
import ImageUploading from "react-images-uploading";
// spinner
import {Spinner} from "../../../../../original/js/spinner";
// functions
import {getLike} from "../../../../../original/js/like";
// component
import {SideBar} from "./layout/sideBar";
import {Loader} from "../../../loader/page/loader";

const ItemPanel = lazy(() => import('./child/itemPanel').then(module => ({default: module.ItemPanel})));

export const Panel = () => {
    const [item, setItem] = useState([{}]);
    const [count, setId] = useState(null);
    const [userLikeGallery, setUserLike] = useState([{}]);
    const [userName, setUsrName] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    // save images
    const [images, setImages] = useState([]);
    const [images_2, setSend] = useState([]);
    // token
    // get user info
    let token = Cookies.get('emarat_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const getGallery = () => {
        axios.get(`${routes[5].address}gallery`, config)
            .then((response) => {
                setItem(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }

    useEffect(() => {
        getGallery();
        getLike(setUserLike);
        let user_name = localStorage.getItem('user_name');
        setUsrName(user_name);
        setTimeout(() => {
            setIsLoading(false);
        }, 2500);
    }, []);
    // limit upload
    const maxNumber = 1;
    const onChange = (imageList) => {
        // data for submit
        setImages(imageList);
    };

    const onChange_2 = (imageList) => {
        setSend(imageList);
    }

    const userUpdate = (id) => {
        setId(id);
    }
    const sendImage = async (btn) => {
        Spinner(btn);
        let submit = document.getElementById(btn)
        let data = new FormData();
        data.append(`name`, document.getElementById('name-user').value);
        if (images[0]) {
            data.append(`image`, images[0].file);
        }
        data.append('_method', 'PUT')
        await axios.post(`${routes[5].address}gallery/${count}`, data, config)
            .then(() => {
                submit.innerHTML = 'تبریک !';
                document.getElementById('image-alert').innerHTML = null;
                document.getElementById('name-alert').innerHTML = null;
                getGallery();
                getLike();
                submit.disabled = false;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.image) {
                        document.getElementById('image-alert').innerHTML = error.response.data.message.image;
                    } else {
                        document.getElementById('image-alert').innerHTML = null;
                    }
                    if (error.response.data.message.name) {
                        document.getElementById('name-alert').innerHTML = error.response.data.message.name;
                    } else {
                        document.getElementById('name-alert').innerHTML = null;
                    }
                    if (error.response.data.message === "Unauthenticated.") {
                        submit.innerHTML = 'برای شرکت ابتدا باید در سایت ثبت نام کنید...';
                    } else {
                        submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    }
                    submit.disabled = false;
                }
            })
    }

    const deleteUser = async (id) => {
        await Swal.fire({
            title: 'مطمعنی?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            background: '#1E1E1E',
            confirmButtonColor: '#84BD41',
            cancelButtonColor: '#d33',
            confirmButtonText: 'حذف',
            cancelButtonText: 'انصراف',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "حذف شد!",
                    background: '#1E1E1E',
                    text: "عکس مد نظر با موفقیت حذف شد",
                    icon: "success",
                    confirmButtonText: 'ادامه',
                })
                axios.delete(`${routes[5].address}gallery/${id}`, config)
                    .then(function () {
                        getGallery()
                        getLike()
                    })
                    .catch(function (error) {
                    });
            }
        })
    }

    const userLike = async (id, setUserLike) => {
        await axios.post(`${routes[5].address}like/${id}`, {}, config)
            .then(() => {
                getGallery();
                getLike(setUserLike);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message === "شما قبلا این گالری را لایک کرده اید.") {
                        axios.delete(`${routes[5].address}unlike/${id}`, config)
                            .then(() => {
                                getGallery();
                                getLike(setUserLike);
                            })
                            .catch((error) => {
                                if (error.response) {
                                }
                            })
                    }
                    if (error.response.data.message === "Unauthenticated.") {
                        Swal.fire({
                            position: "top-center",
                            icon: "error",
                            background: '#1E1E1E',
                            title: "برای لایک کردن ابتدا باید ثبت نام کنید !",
                            showConfirmButton: true,
                            confirmButtonText: 'تایید',
                            confirmButtonColor: "#C07B36",
                            timer: 3000
                        });
                    }
                }
            })
    }
    const sendPanelImage = async (btn) => {
        Spinner(btn)
        let submit = document.getElementById(btn)
        let data = new FormData();
        data.append(`name`, document.getElementById('name').value);
        if (images_2[0]) {
            data.append(`image`, images_2[0].file);
        }
        await axios.post(`${routes[5].address}gallery`, data, config)
            .then(() => {
                submit.innerHTML = 'تبریک !';
                getGallery();
                getLike();
                document.getElementById('image-panel-alert').innerHTML = null;
                document.getElementById('name-panel-alert').innerHTML = null;
                submit.disabled = false;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.image) {
                        document.getElementById('image-panel-alert').innerHTML = error.response.data.message.image;
                    } else {
                        document.getElementById('image-panel-alert').innerHTML = null;
                    }
                    if (error.response.data.message.name) {
                        document.getElementById('name-panel-alert').innerHTML = error.response.data.message.name;
                    } else {
                        document.getElementById('name-panel-alert').innerHTML = null;
                    }
                    if (error.response.data.message === "Unauthenticated.") {
                        submit.innerHTML = 'برای شرکت ابتدا باید در سایت ثبت نام کنید...';
                    } else {
                        submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    }
                    submit.disabled = false;
                }
            })
    }

    return (
        <Fragment>
            <Seo
                title="عمارت سران | پنل کاربری | گالری"
                description="پنل کاربری عمارت سران !"
                type="webapp"
                name="progogram"
            />
            {isLoading ? (
                <Loader />
            ) : (
                <section id="user" className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-5 col">
                            <SideBar/>
                        </div>
                        <div className="col-lg-9 col-md-7 mt-md-0 mt-4">
                            <div className="card card-body shadow worker-card">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="card-title h6 fw-bold">گالری</span>
                                    <button data-bs-toggle="modal"
                                            data-bs-target="#gallery-panel-modal"
                                            className="btn btn-outline-site">شرکت در
                                        گالری
                                    </button>
                                </div>
                                <p className="mb-0 mt-4 font-12 text-site">
                                    <IconAlertCircle size={12} className="ms-1"/>
                                    کاربر عزیز عکس شما پس از تایید ادمین در سایت منتشر خواهد شد !
                                </p>
                                <div className="row mt-3">
                                    {item.map((photo, index) => (
                                        <div key={index} className="col-lg-4 mb-4">
                                            <ItemPanel
                                                deleteUser={() => deleteUser(photo.id)}
                                                update={() => userUpdate(photo.id)}
                                                approved={photo.approved}
                                                userLike={() => userLike(photo.id, setUserLike)}
                                                image={photo.image}
                                                like={photo.likes}
                                                time={photo.created_at}
                                                name={photo.name}
                                                id={photo.id}
                                                gallery={item}
                                                likeGallery={userLikeGallery}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="gallery-updated-modal">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-between">
                                    <span className="card-title mb-0 h6 fw-bold">شرکت در گالری</span>
                                    <IconX color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                                </div>
                                <div className="modal-body">
                                    <div className="form text-center">
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   defaultValue={userName}
                                                   required={true}
                                                   id="name-user"
                                                   name="name"
                                                   placeholder="نام یا آیدی اینستاگرام"
                                                   className="form-control font-14"/>
                                            <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="name-alert"></span>
                                        </p>
                                        <fieldset className="mt-4">
                                            <div className="image" id="page-image">
                                                <ImageUploading
                                                    multiple
                                                    value={images}
                                                    onChange={onChange}
                                                    maxNumber={maxNumber}
                                                    dataURLKey="data_url"
                                                >
                                                    {({
                                                          imageList,
                                                          onImageUpload,
                                                          onImageUpdate,
                                                          isDragging,
                                                          dragProps,
                                                      }) => (
                                                        // write your building UI
                                                        <div className="upload__image-wrapper">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button
                                                                        className="upload-btn rounded rounded-3"
                                                                        style={isDragging ? {color: 'red'} : undefined}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        برای آپلود کلیک کنید
                                                                    </button>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <div
                                                                        className="card img-uploader-card card-body border-0">
                                                                        {imageList.map((image, index) => (
                                                                            <div
                                                                                className="d-flex justify-content-center"
                                                                                key={index}>
                                                                                <div
                                                                                    className="image-item">
                                                                                    <img
                                                                                        src={image['data_url']}
                                                                                        alt="upload"
                                                                                        id="image-upload-echo"
                                                                                        className="img-fluid image-upload-done mt-3 mb-2"/>
                                                                                    <div
                                                                                        className="image-item__btn-wrapper">
                                                                                        <button
                                                                                            className="upload-btn text-center"
                                                                                            onClick={() => onImageUpdate(index)}>
                                                                                            آپدیت
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </div>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="image-alert"></span>
                                        </p>
                                        <button id="send-img" onClick={() => sendImage('send-img')}
                                                className="btn btn-site my-4 w-100">
                                            ارسال عکس
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="gallery-panel-modal">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-between">
                                    <span className="card-title mb-0 h6 fw-bold">شرکت در گالری</span>
                                    <IconX color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                                </div>
                                <div className="modal-body">
                                    <div className="form text-center">
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   defaultValue={userName}
                                                   required={true}
                                                   id="name"
                                                   name="name"
                                                   placeholder="نام یا آیدی اینستاگرام"
                                                   className="form-control font-14"/>
                                            <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="name-panel-alert"></span>
                                        </p>
                                        <fieldset className="mt-4">
                                            <div className="image" id="page-image">
                                                <ImageUploading
                                                    multiple
                                                    value={images_2}
                                                    onChange={onChange_2}
                                                    maxNumber={maxNumber}
                                                    dataURLKey="data_url"
                                                >
                                                    {({
                                                          imageList,
                                                          onImageUpload,
                                                          onImageUpdate,
                                                          isDragging,
                                                          dragProps,
                                                      }) => (
                                                        // write your building UI
                                                        <div className="upload__image-wrapper">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button
                                                                        className="upload-btn rounded rounded-3"
                                                                        style={isDragging ? {color: 'red'} : undefined}
                                                                        onClick={onImageUpload}
                                                                        {...dragProps}
                                                                    >
                                                                        برای آپلود کلیک کنید
                                                                    </button>
                                                                </div>
                                                                <div className="col-12 mt-3">
                                                                    <div
                                                                        className="card img-uploader-card card-body border-0">
                                                                        {imageList.map((image, index) => (
                                                                            <div
                                                                                className="d-flex justify-content-center"
                                                                                key={index}>
                                                                                <div
                                                                                    className="image-item">
                                                                                    <img
                                                                                        src={image['data_url']}
                                                                                        alt="upload"
                                                                                        id="image-upload-echo"
                                                                                        className="img-fluid image-upload-done mt-3 mb-2"/>
                                                                                    <div
                                                                                        className="image-item__btn-wrapper">
                                                                                        <button
                                                                                            className="upload-btn text-center"
                                                                                            onClick={() => onImageUpdate(index)}>
                                                                                            آپدیت
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                            </div>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="image-panel-alert"></span>
                                        </p>
                                        <button id="send-img-panel"
                                                onClick={() => sendPanelImage('send-img-panel')}
                                                className="btn btn-site my-4 w-100">
                                            ارسال عکس
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    )
}