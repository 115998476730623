// style
import './../css/header.css';
// icons
import {
    IconBooks,
    IconDeviceGamepad,
    IconHome,
    IconPhoto,
    IconToolsKitchen,
    IconUser
} from "@tabler/icons-react";
// link
import {Link, NavLink} from "react-router-dom";
// routes
import {routes} from "../../../../original/route/route";
// hook
import {Fragment, useEffect, useState} from "react";
// logo
import logo from './../../../../original/image/final logo-1_prev_ui.png'
// axios
import axios from "axios";

export const Header = (props) => {
    const [status, setStatus] = useState(true);
    const changeResize = () => {
        if (window.innerWidth < 766) {
            setStatus(false)
        } else {
            setStatus(true)
        }
    }
    window.addEventListener('resize', changeResize);
    useEffect(() => {
        changeResize();
    }, []);

    const login = () => {
        axios.get(`${routes[7].send_user}`)
            .then((response) => {
                window.open(response.data.url, '_self')
            })
            .catch((error) => {
                if (error.response) {}
            })
    }
    return (
        // header
        <Fragment>
            <header className="container py-4" data-aos="fade-up" data-aos-duration="1500">
                <nav className="navbar navbar-expand-md navbar-dark shadow">
                    <div className="container-fluid pe-0 ps-4">
                        <Link to={routes[0].address} className="navbar-brand pe-0 text-site fw-bold align-middle">
                            <img src={logo ? logo : ''} alt="logo" className="img-fluid logo"/>
                        </Link>
                        {props.access ?
                            (
                                <NavLink to={routes[9].address}
                                         className="btn navbar-toggler btn-outline-site align-middle me-3 py-2">
                                    <IconUser/>
                                </NavLink>
                            )
                            :
                            (
                                <button onClick={() => login()}
                                        className="btn navbar-toggler btn-site align-middle me-2 py-2">
                                    ورود / ثبت نام
                                </button>
                            )
                        }
                        {status ?
                            (
                                <>
                                    <div className="collapse navbar-collapse justify-content-end">
                                        <div className="d-flex align-content-center">
                                            <ul className="navbar-nav nav">
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={routes[0].address}
                                                        className="nav-link ms-2">
                                                        خانه
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={routes[3].address}
                                                        className="nav-link ms-2">
                                                        منو
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={routes[1].address}
                                                        className="nav-link ms-2">
                                                        درباره ما
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={routes[2].address}
                                                        className="nav-link">
                                                        گالری
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink
                                                        to={routes[10].address}
                                                        className="nav-link">
                                                        بازی
                                                    </NavLink>
                                                </li>
                                            </ul>
                                            {props.access ?
                                                (
                                                    <NavLink
                                                        to={routes[9].address}
                                                        className="btn btn-outline-site align-middle me-3 py-2">
                                                        <IconUser/>
                                                    </NavLink>
                                                )
                                                :
                                                (
                                                    <button onClick={() => login()}
                                                            className="btn btn-site align-middle me-3 py-2">
                                                        ورود / ثبت نام
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            (<></>)
                        }
                    </div>
                </nav>
            </header>
            {!status ?
                (
                    <>
                        <section className="container mobile-menu">
                            <div
                                className="header-mobile rounded rounded-5 rounded-bottom-0 py-2 pt-4 w-100 d-md-none d-inline-block position-fixed bottom-0 end-0">
                                <div className="container px-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="btn-phone text-site">
                                            <NavLink
                                                to={routes[0].address}
                                                className="btn btn-outline-site">
                                                <IconHome/>
                                            </NavLink>
                                            <p className="mb-0 text-center mt-2">خانه</p>
                                        </div>
                                        <div className="btn-phone text-site">
                                            <NavLink
                                                to={routes[3].address}
                                                className="btn btn-outline-site">
                                                <IconToolsKitchen/>
                                            </NavLink>
                                            <p className="mb-0 text-center mt-2">منو</p>
                                        </div>
                                        <div className="btn-phone text-site">
                                            <NavLink
                                                to={routes[1].address}
                                                className="btn btn-outline-site">
                                                <IconBooks/>
                                            </NavLink>
                                            <p className="mb-0 text-center mt-2">درباره ما</p>
                                        </div>
                                        <div className="btn-phone text-site">
                                            <NavLink
                                                to={routes[2].address}
                                                className="btn btn-outline-site">
                                                <IconPhoto/>
                                            </NavLink>
                                            <p className="mb-0 text-center mt-2">گالری</p>
                                        </div>
                                        <div className="btn-phone text-site">
                                            <NavLink
                                                to={routes[10].address}
                                                className="btn btn-outline-site">
                                                <IconDeviceGamepad/>
                                            </NavLink>
                                            <p className="mb-0 text-center mt-2">بازی</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
                :
                (<></>)
            }
        </Fragment>
    )
}