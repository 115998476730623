// react section
import {Fragment, useEffect, useState} from "react";
// link
import {Link, NavLink} from "react-router-dom";
import {routes} from "../../../../../../original/route/route";
import axios from "axios";
import Cookies from "js-cookie";

export const SideBar = () => {
    const [user, setUser] = useState({});
    const token = Cookies.get('emarat_token');
    const logOut = () => {
        // get user info
        let token = Cookies.get('emarat_token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        axios.post(`${routes[5].address}logout`, {}, config)
            .then(() => {
                localStorage.removeItem('user_name');
                localStorage.removeItem('email');
                Cookies.remove('emarat_token');
                window.open(routes[0].address, '_self');
            })
    }
    useEffect(() => {
        let user_name = localStorage.getItem('user_name');
        let user_email = localStorage.getItem('email');
        setUser({name: user_name, email: user_email});
        if (!token) {
            logOut();
        }
    }, []);
    return (
        <Fragment>
            <div className="card card-body shadow worker-card">
                <ul className="list-group px-0">
                    <li className="list-group-item border-0 border-bottom border-white">
                        <NavLink className="text-decoration-none text-site fw-bold" to={routes[9].address}>
                            <p className="mb-0">
                                {user.name ? user.name : 'کاربر'}
                            </p>
                            <p className="font-14 card-text mb-0">
                                {user.email ? user.email : 'ایمیل'}
                            </p>
                        </NavLink>
                    </li>
                    <li className="list-group-item border-0 border-bottom border-white mt-1">
                        <NavLink className="text-decoration-none text-white" to={routes[9].address}>گالری</NavLink>
                    </li>
                    <li className="list-group-item border-0 border-bottom border-white mt-1">
                        <NavLink className="text-decoration-none text-white" to={routes[11].address}>موزیک</NavLink>
                    </li>
                    <li className="list-group-item border-0 mt-1">
                        <Link to={'#'} className="text-decoration-none card-text" onClick={() => logOut()}>
                            خروج از حساب
                        </Link>
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}