// start react
import React from 'react';
import ReactDOM from 'react-dom/client';
// add bootstrap for global
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.css';
// App component
import App from './App';
// react router
import {BrowserRouter} from "react-router-dom";
// seo
import {HelmetProvider} from "react-helmet-async";

const helmetContext = {};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // render component
    <HelmetProvider context={helmetContext}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </HelmetProvider>
);

