// style
import './../css/menu.css';
// react section
import {Fragment, lazy, Suspense, useEffect, useState} from "react";
// seo
import {Seo} from "../../../layout/seo/seo";
// react dom
import {useParams} from "react-router-dom";
import {routes} from "../../../../original/route/route";
// axios
import axios from "axios";
// image
import banner from "../../../../original/image/coffee_menu.jpg";
import restaurant from '../../../../original/image/restaurant_img.webp';
// component
import {Banner} from "../../../layout/banner/page/banner";
import {numberFormat} from "../../../../original/js/numberFormat";
// sweatAlert
import logo from "../../../../original/image/final logo-1_prev_ui.png";
import Cookies from "js-cookie";

const MenuItem = lazy(() => import('./child/menuItem').then(module => ({default: module.MenuItem})));


export const Menu = () => {
    const {name} = useParams();
    const [allitems, setAllItems] = useState([{}]);
    const [items, setItems] = useState([{}]);
    const [allcategories, setAllCategory] = useState([{}]);
    const [categories, setCategory] = useState([{}]);
    const [isLoading, setIsLoading] = useState(true);
    let address = ``;
    if (name === 'coffee') {
        address = `COFFEE_SHOP`;
    } else if (name === 'restaurant') {
        address = `RESTAURANT`;
    }
    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get('emarat_token_api')}`
            }
        }
        axios.get(`https://emaratesaran.ir:1020/product/getAllProducts`, config)
            .then((response) => {
                setAllItems(response.data.productList);
                let newItems = response.data.productList;
                newItems = newItems.filter((elm) => elm.productBranch.value === address);
                newItems = newItems.filter((elm) => elm.active === true);
                setItems(newItems);
                numberFormat();
                setIsLoading(false)
            })
            .catch((error) => {
                if (error.response) {
                }
            })
        axios.get(`https://emaratesaran.ir:1020/category/getAllCategory`, config)
            .then((response) => {
                setAllCategory(response.data.categoryList)
                let newItems = response.data.categoryList;
                newItems = newItems.filter((elm) => elm.categoryBranch.value === address);
                newItems = newItems.filter((elm) => elm.active === true);
                setCategory(newItems)
            })
            .catch((error) => {
                if (error.response) {
                }
            })
        numberFormat();
    }, []);
    const changeItem = async (index, id) => {
        let elem = document.getElementsByClassName('category-btn')
        for (let i = 0; i < elem.length; i++) {
            if (i === index) {
                elem[i].className = "btn btn-site shadow category-btn me-2 ";
            } else {
                elem[i].className = "btn btn-outline shadow category-btn me-2";
            }
        }
        let newItems = allitems;
        newItems = allitems.filter((elm) => elm.categoryInfo.id === id)
        setItems(newItems);
        numberFormat();
    }
    return (
        <Fragment>
            <Seo
                title={name === "coffee" ? ' عمارت سران | منو کافه' : ' عمارت سران | منو رستوران'}
                description={name === "coffee" ? ' کافه عمارت سران با چشم نواز ترین دیزاین در کنار بهترین متریال ها آماده پذیرایی از شما عزیزان می باشد' : 'رستوران عمارت سران در کنار لذیذ ترین غذا ها یک فضای مدرن هم نیز در اختیار شما می گذارد تا نهایت لذت را از کنار یکدیگر بودن ببرید...'}
                keywords="عمارت سران،کافه،رستوران،لذیذ،بهترین"
                type="webapp"
                name="progogram"
            />
            <section id="menu" className="container">
                <Banner image={name === "coffee" ? banner : restaurant}
                        title={name === "coffee" ? 'کافه' : 'رستوران'}
                        description={name === "coffee" ? ' کافه عمارت سران با چشم نواز ترین دیزاین در کنار بهترین متریال ها آماده پذیرایی از شما عزیزان می باشد' : 'رستوران عمارت سران در کنار لذیذ ترین غذا ها یک فضای مدرن هم نیز در اختیار شما می گذارد تا نهایت لذت را از کنار یکدیگر بودن ببرید...'}
                        link={routes[2].address}
                        link_text={'گالری'}/>
                {isLoading ? (
                    <>
                        <div className={'text-center'}>
                            <img src={logo ? logo : ''} alt="logo" className="img-fluid logo-big mt-4"/>
                            <p className={'text-site font-14 mb-0 mt-3 text-center'}>
                                لطفا چند لحظه منتظر بمانید...
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <div id="categories" className="mt-5">
                            <div className="text-center">
                                <p className="h2 text-site fw-bold text-center mt-5">منو {name === "coffee" ? 'کافه' : 'رستوران'}</p>
                                <img alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                                     className="img-fluid divider mt-2"/>
                            </div>
                            <div className="category-father">
                                <div
                                    className="row flex-nowrap justify-content-md-center justify-content-start pe-md-0 pe-3 text-center mt-4">
                                    {categories.map((category, index) => (
                                        <button className="btn btn-outline shadow me-2 category-btn"
                                                onClick={() => changeItem(index, category.id)}
                                                key={index}>
                                            {category.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div id="items" className="mt-5">
                            <div className="row">
                                <Suspense>
                                    {items.map((item, index) => (
                                        <div className="col-lg-3 mb-4" key={index}>
                                            <MenuItem
                                                name={item.title}
                                                description={item.description ? item.description : item.title}
                                                price={item.price} image={item.img}/>
                                        </div>
                                    ))}
                                </Suspense>
                            </div>
                        </div>
                    </>
                )}
            </section>
        </Fragment>
    )
}