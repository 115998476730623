// react section
import {Fragment} from "react";
// link
import {Link} from "react-router-dom";

export const Banner = (props) => {
    return (
        <Fragment>
            {/* banner */}
            <div id="banner" className="rounded rounded-4 mt-5" style={{backgroundImage: `url(${props.image})`}}>
                <div className="container h-100">
                    <div className="row justify-content-start align-items-center h-100">
                        <div className="col-lg-4" data-aos="fade-down" data-aos-duration="2000">
                            <h1 className="card-title fw-bold">{props.title}</h1>
                            <img alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                                 className="img-fluid mt-2"/>
                            <p className="card-text fw-bold mt-2">
                                {props.description}
                            </p>
                            <Link to={props.link} className="btn btn-outline-site px-4">{props.link_text}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}