// base routes
export const routes = [
    {
        name: 'home',
        address: '/',
        group: 'home'
    },
    {
        name: 'about',
        address: '/about',
        group: 'about'
    },
    {
        name: 'gallery',
        address: '/gallery',
        group: 'gallery'
    },
    {
        name: 'branch',
        address: '/branch',
        group: 'branch'
    },
    {
        name: 'menu',
        address: '/menu/:name',
        group: 'menu'
    },
    // api
    {
        name: 'api',
        address: 'https://dashboard.emaratsaran.ir/api/',
        group: 'api'
    },
    {
        name: 'image',
        address_item: 'https://dashboard.emaratsaran.ir/uploads/suggestions/images/',
        address_worker: 'https://dashboard.emaratsaran.ir/uploads/workers/images/',
        address_game: 'https://dashboard.emaratsaran.ir/uploads/games/images/',
        address_gallery: 'https://dashboard.emaratsaran.ir/storage/gallery/users/images/',
        group: 'image'
    },
    // google
    {
        name: 'google',
        send_user: 'https://dashboard.emaratsaran.ir/api/auth/google',
        get_user: 'https://dashboard.emaratsaran.ir/api/auth/google/callback',
        group: 'google',
    },
    {
        name: 'google',
        address: '/auth/google',
        group: 'google',
    },
    // auth
    {
        name: 'user',
        address: "/user/panel",
        group: 'user',
    },
    {
        name: 'game',
        address: "/games",
        group: 'game',
    },
    {
        name: 'user',
        address: "/user/music",
        group: 'user',
    }
]