// react section
import {Fragment, useEffect, useState} from "react";
// component
import {Banner} from "../../../layout/banner/page/banner";
// seo
import {Seo} from "../../../layout/seo/seo";
// link
import {Link} from "react-router-dom";
import {routes} from "../../../../original/route/route";
// image
import banner from "../../../../original/image/game.webp";
import axios from "axios";

export const Game = () => {
    const [game, setGame] = useState([]);
    useEffect(() => {
        axios.get(`${routes[5].address}games`)
            .then((response) => {
                setGame(response.data.data)
            })
            .catch((error) => {

            })
    }, []);
    return (
        <Fragment>
            <Seo
                title="عمارت سران | بازی"
                description="می توانید در اینجا با بازی کردن در کنار دوستان خود لحظات شادی را برای یکدیگر رقم بزنید!"
                keywords="عمارت سران،بازی،لحظات شاد"
                type="webapp"
                name="progogram"
            />
            <section id="about" className="container">
                <Banner image={banner}
                        title={'گیم (بازی)'}
                        description={
                            `می توانید در اینجا با بازی کردن در کنار دوستان خود لحظات شادی را برای یکدیگر رقم بزنید و سرگرمی را نیز تجربه کنید!`
                        }
                        link={routes[0].address}
                        link_text={'خانه'}/>
                <div className="row justify-content-center mt-5">
                    <div className="text-center">
                        <p className="h2 text-site fw-bold text-center">بازی ها</p>
                        <img alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                             className="img-fluid divider mt-2 mb-2"/>
                    </div>
                    {game.map((item, index) => (
                        <div key={index} className="col-lg-4 mb-4">
                            <Link to={item.url}
                                  className="text-decoration-none font-12">
                                <div className="card card-body shadow worker-card hover-card">
                                    <div className="card-img">
                                        <img alt="coffe"
                                             src={`${routes[6].address_game}${item.image}`}
                                             className="img-fluid branch-img rounded rounded-4"/>
                                    </div>
                                    <div className="card-texts mt-4">
                                        <p className="card-title fw-bold h5">{item.name}</p>
                                        <div className="d-flex justify-content-end mt-3">
                                            <Link to={item.url}
                                                  className="text-site text-decoration-none font-12">
                                                مشاهده بازی
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>
        </Fragment>
    )
}