// react section
import {Fragment} from "react";
import {IconEdit, IconTrash} from "@tabler/icons-react";

export const MusicItem = (props) => {
    return (
        <Fragment>
            <div className="card card-body worker-card border-0 shadow">
                <div className="d-md-flex justify-content-between align-items-center">
                <span className="card-text mb-0">
                    نام موزیک :
                    <span className="align-middle card-title mb-0 me-1">
                        {props.title}
                    </span>
                </span>
                    <div className="d-flex justify-content-md-start justify-content-end method">
                        <IconEdit
                            onClick={() => props.EditeMusic(props.id)}
                            size={16}
                            className="text-primary ms-1"
                            data-bs-toggle="modal"
                            data-bs-target={'#update-music-modal'}
                        />
                        <IconTrash
                            onClick={() => props.DeleteMusic(props.id)}
                            size={16}
                            className="text-danger"/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}