import logo from "../../../../original/image/final logo-1_prev_ui.png";

export const Loader = () => {
    return (
        <>
            <div className={'text-center'}>
                <img src={logo ? logo : ''} alt="logo" className="img-fluid logo-big mt-4"/>
                <p className={'text-site font-14 mb-0 mt-3 text-center'}>
                    لطفا چند لحظه منتظر بمانید...
                </p>
            </div>
        </>
    )
}