// axios
import axios from "axios";
// route
import {routes} from "../route/route";
// cookie
import Cookies from "js-cookie";
// get user info
let token = Cookies.get('emarat_token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}
export const getLike = (setUserLike) => {
    axios.post(`${routes[5].address}check-user-like`, {}, config)
        .then((response) => {
            setUserLike(response.data.data);
        })
        .catch((error) => {
            if (error.response) {
            }
        })
}