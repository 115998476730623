// react section
import {Fragment} from "react";
// seo
import {Seo} from "../../../layout/seo/seo";
// link
import {Link} from "react-router-dom";
import {routes} from "../../../../original/route/route";
// component
import {Banner} from "../../../layout/banner/page/banner";
// image
import banner from "../../../../original/image/menu_page.jpg";


export const Brnach = () => {
    return (
        <Fragment>
            <Seo
                title="عمارت سران | بخش"
                description="عمارت سران دارای دو بخش رستوران و کافه است که بهترین دکوراسیون و همچنین لذیذ ترین غذا هاو آیتم هارا در اختیار شما میگذارد تا نهایت لذت را در این کافه رستوران ببرید !"
                type="webapp"
                keywords="عمارت سران،کافه،رستوران،لذیذ"
                name="progogram"
            />
            <section id="branch" className="container">
                <Banner image={banner}
                        title={'طبقات'}
                        description={
                            `عمارت سران دارای دو بخش رستوران و کافه است که بهترین دکوراسیون و همچنین لذیذ ترین غذا ها
                            و آیتم هارا در اختیار شما میگذارد تا نهایت لذت را در این کافه رستوران ببرید`
                        }
                        link={routes[2].address}
                        link_text={'گالری'}/>
                <div id="branch_item" className="text-center mt-5">
                    <p className="h2 text-site fw-bold text-center mt-5">منو طبقات</p>
                    <img alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                         className="img-fluid divider mt-2"/>
                    <div id="items" className="row justify-content-center mt-3">
                        <div className="col-lg-4 col-md-6 mb-md-0 mb-4">
                            <Link to="/menu/coffee" className="text-decoration-none font-12">
                                <div className="card card-body shadow worker-card hover-card">
                                    <div className="card-img">
                                        <img alt="coffe" src={require('./../../../../original/image/coffee_menu.jpg')}
                                             className="img-fluid branch-img rounded rounded-4"/>
                                    </div>
                                    <div className="card-texts mt-3">
                                        <p className="card-title fw-bold h5">کافه عمارت</p>
                                        <p className="card-text my-2">بخش کافه عمارت سران با بهترین نوشیدنی ها و آیتم
                                            ها</p>
                                        <div className="d-flex justify-content-end mt-3">
                                            <Link to="/menu/coffee" className="text-site text-decoration-none font-12">
                                                مشاهده منو
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-md-0 mb-4">
                            <Link to="/menu/restaurant" className="text-decoration-none font-12">
                                <div className="card card-body shadow worker-card hover-card">
                                    <div className="card-img">
                                        <img alt="coffe"
                                             src={require('./../../../../original/image/restaurant_img.webp')}
                                             className="img-fluid branch-img rounded rounded-4"/>
                                    </div>
                                    <div className="card-texts mt-3">
                                        <p className="card-title fw-bold h5">رستوران عمارت</p>
                                        <p className="card-text my-2">بخش رستوران عمارت سران با لذیذ ترین و بهترین غذا
                                            ها</p>
                                        <div className="d-flex justify-content-end mt-3">
                                            <Link to="/menu/restaurant"
                                                  className="text-site text-decoration-none font-12">
                                                مشاهده منو
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}