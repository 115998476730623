// style
import './App.css';
// react section
import {Fragment, Suspense, useEffect, useRef, useState} from "react";
// components
import {Header} from "./components/layout/header/page/header";
import {Footer} from "./components/layout/footer/page/footer";
import {PageRouter} from "./components/layout/router/page/router";
import {Modal} from "./components/layout/modal/page/modal";
// animation
import AOS from 'aos';
import 'aos/dist/aos.css';
// cookie
import Cookies from 'js-cookie';

const App = () => {
    const [access, setAccess] = useState(null);
    const prevAccess = useRef(null);
    const token = Cookies.get('emarat_token');
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "JSESSIONID=4F95A2FFAB4487DB2CBCF7AF1C329A6D");
        const raw = JSON.stringify({
            "username": "apiOne",
            "password": "H1578856#777"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://emaratesaran.ir:1020/user/login", requestOptions)
            .then((response) => response.json())
            .then((result) => Cookies.set('emarat_token_api', result.token, {expires: 5}))
            .catch((error) => console.error(error));
        if (token) {
            setAccess(true)
        } else {
            setAccess(false)
            localStorage.removeItem('user_name');
            localStorage.removeItem('email');
            Cookies.remove('emarat_token');
            if (prevAccess.current === true) {
                window.open(window.location.pathname);
            }
            prevAccess.current = access;
        }
        // animation
        AOS.init();
    }, []);
    return (
        <Fragment>
            <Suspense>
                {/* header */}
                <Header access={access}/>
                {/* end header */}
                {/* routes */}
                <PageRouter access={access}/>
                {/* end routes */}
                {/* start footer */}
                <Footer/>
                {/* end footer */}
                {/*  team modal  */}
                <Modal/>
                {/*  end team modal  */}
            </Suspense>
        </Fragment>
    );
}

export default App;
