// axios
import axios from "axios";
// routes
import {routes} from "../route/route";
// functions
import {getGallery} from "./gallery";
import {getLike} from "./like";
// sweatAlert
import Swal from "sweetalert2";
// cookie
import Cookies from "js-cookie";
// get user info
let token = Cookies.get('emarat_token');
const config = {
    headers: {
        Authorization: `Bearer ${token}`
    }
}
export const userLike = async (id, setItem, setUserLike) => {
    await axios.post(`${routes[5].address}like/${id}`, {}, config)
        .then(() => {
            document.getElementById(`like-gallery${id}`).classList.add('animate');
            getGallery(setItem);
            getLike(setUserLike);
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.data.message === "شما قبلا این گالری را لایک کرده اید.") {
                    axios.delete(`${routes[5].address}unlike/${id}`, config)
                        .then(() => {
                            getGallery(setItem);
                            getLike(setUserLike);
                        })
                        .catch((error) => {
                            if (error.response) {
                            }
                        })
                }
                if (error.response.data.message === "Unauthenticated.") {
                    Swal.fire({
                        position: "top-center",
                        icon: "error",
                        background: '#1E1E1E',
                        title: "برای لایک کردن ابتدا باید ثبت نام کنید !",
                        showConfirmButton: true,
                        confirmButtonText: 'تایید',
                        confirmButtonColor: "#C07B36",
                        timer: 3000
                    });
                }
            }
        })
}
