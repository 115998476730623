// react section
import {Fragment, useEffect, useState} from "react";
// seo
import {Seo} from "../../../../layout/seo/seo";
// components
import {SideBar} from "./layout/sideBar";
import {MusicItem} from "./child/musicItem";
import {Spinner} from "../../../../../original/js/spinner";
// axios
import axios from "axios";
// routes
import {routes} from "../../../../../original/route/route";
// cookie
import Cookies from "js-cookie";
// alert
import Swal from "sweetalert2";
// icon
import {IconX} from "@tabler/icons-react";
import {Loader} from "../../../loader/page/loader";


export const Music = () => {
    const [item, setItem] = useState([{}]);
    const [count, setId] = useState(null);
    const [userName, setUsrName] = useState('')
    const [isLoading, setIsLoading] = useState(true);
    // token
    // get user info
    let token = Cookies.get('emarat_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const getMusic = () => {
        axios.get(`${routes[5].address}suggestion`, config)
            .then((response) => {
                setItem(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }
    useEffect(() => {
        getMusic();
        let user_name = localStorage.getItem('user_name');
        setUsrName(user_name);
        setTimeout(() => {
            setIsLoading(false);
        }, 2500);
    }, []);
    const userUpdate = (id) => {
        setId(id);
    }
    const deleteMusic = async (id) => {
        await Swal.fire({
            title: 'مطمعنی?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            background: '#1E1E1E',
            confirmButtonColor: '#84BD41',
            cancelButtonColor: '#d33',
            confirmButtonText: 'حذف',
            cancelButtonText: 'انصراف',
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "حذف شد!",
                    background: '#1E1E1E',
                    text: "پیشنهاد مد نظر با موفقیت حذف شد",
                    icon: "success",
                    confirmButtonText: 'ادامه',
                })
                axios.delete(`${routes[5].address}suggestion/${id}`, config)
                    .then(function () {
                        getMusic()
                    })
                    .catch(function (error) {
                    });
            }
        })
    }
    const sendMusic = async (btn) => {
        Spinner(btn)
        let submit = document.getElementById(btn);
        await axios.post(`${routes[5].address}suggestion/${count}`, {
            user_name: document.getElementById('user_name').value,
            music_name: document.getElementById('user_music').value,
            _method: 'PUT',
        }, config)
            .then(() => {
                submit.innerHTML = "تبریک !";
                submit.disabled = false;
                document.getElementById('user-alert').innerHTML = null;
                document.getElementById('user-music-alert').innerHTML = null;
                getMusic();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.music_name) {
                        document.getElementById('user-music-alert').innerHTML = error.response.data.message.music_name
                    } else {
                        document.getElementById('user-music-alert').innerHTML = null;
                    }
                    if (error.response.data.message.user_name) {
                        document.getElementById('user-alert').innerHTML = error.response.data.message.user_name;
                    } else {
                        document.getElementById('user-alert').innerHTML = null;
                    }
                    submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    submit.disabled = false;
                }
            })
    }
    const sendNewMusic = async (btn) => {
        Spinner(btn)
        let submit = document.getElementById(btn);
        await axios.post(`${routes[5].address}suggestion`, {
            user_name: document.getElementById('user_new_name').value,
            music_name: document.getElementById('user_new_music').value,
            _method: 'PUT',
        }, config)
            .then(() => {
                submit.innerHTML = "تبریک !";
                submit.disabled = false;
                document.getElementById('user-new-alert').innerHTML = null;
                document.getElementById('user-music-new-alert').innerHTML = null;
                getMusic();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.music_name) {
                        document.getElementById('user-music-new-alert').innerHTML = error.response.data.message.music_name
                    } else {
                        document.getElementById('user-music-new-alert').innerHTML = null;
                    }
                    if (error.response.data.message.user_name) {
                        document.getElementById('user-new-alert').innerHTML = error.response.data.message.user_name;
                    } else {
                        document.getElementById('user-new-alert').innerHTML = null;
                    }
                    submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    submit.disabled = false;
                }
            })
    }
    return (
        <Fragment>
            <Seo
                title="عمارت سران | پنل کاربری | پیشنهاد آهنگ"
                description="پنل کاربری عمارت سران !"
                type="webapp"
                name="progogram"
            />
            {isLoading ? (
                <Loader />
            ) : (
                <section id="user" className="container mt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-5 col">
                            <SideBar/>
                        </div>
                        <div className="col-lg-9 col-md-7 mt-md-0 mt-4">
                            <div className="card card-body shadow worker-card">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="card-title h6 fw-bold">موزیک</span>
                                    <button className="btn btn-outline-site" data-bs-toggle='modal'
                                            data-bs-target={'#music-modal'}>
                                        پیشنهاد موزیک
                                    </button>
                                </div>
                                <div className="row mt-3">
                                    {item.map((music, index) => (
                                        <div className="col-lg-6 mb-4" key={index}>
                                            <MusicItem
                                                id={music.id}
                                                title={music.music_name}
                                                EditeMusic={() => userUpdate(music.id)}
                                                DeleteMusic={() => deleteMusic(music.id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="update-music-modal" className="modal fade">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-between">
                                    <span className="card-title mb-0 h6 fw-bold">پیشنهاد موزیک</span>
                                    <IconX color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                                </div>
                                <div className="modal-body">
                                    <div className="form text-center">
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   className="form-control"
                                                   name="user_name"
                                                   placeholder={'نام یا آیدی اینستاگرام'}
                                                   id="user_name"
                                                   defaultValue={userName}/>
                                            <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="user-alert"></span>
                                        </p>
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   className="form-control"
                                                   name="user_music"
                                                   placeholder={'لینک یا نام موزیک پیشنهادی'}
                                                   id="user_music"/>
                                            <label className="form-label font-14">لینک یا نام موزیک پیشنهادی</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="user-music-alert"></span>
                                        </p>
                                        <button id="send-music"
                                                onClick={() => sendMusic('send-music')}
                                                className="btn btn-site w-100 my-4">
                                            بروزرسانی پیشنهاد
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="music-modal" className="modal fade">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header justify-content-between">
                                    <span className="card-title mb-0 h6 fw-bold">پیشنهاد موزیک</span>
                                    <IconX color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                                </div>
                                <div className="modal-body">
                                    <div className="form text-center">
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   className="form-control"
                                                   name="user_new_name"
                                                   placeholder={'نام یا آیدی اینستاگرام'}
                                                   id="user_new_name"
                                                   defaultValue={userName}/>
                                            <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="user-new-alert"></span>
                                        </p>
                                        <fieldset className="form-floating mt-4">
                                            <input type="text"
                                                   className="form-control"
                                                   name="user_new_music"
                                                   placeholder={'لینک یا نام موزیک پیشنهادی'}
                                                   id="user_new_music"/>
                                            <label className="form-label font-14">لینک یا نام موزیک پیشنهادی</label>
                                        </fieldset>
                                        <p className="text-site mt-2 font-12 mb-0">
                                            <span id="user-music-new-alert"></span>
                                        </p>
                                        <button id="send-music"
                                                onClick={() => sendNewMusic('send-new-music')}
                                                className="btn btn-site w-100 my-4">
                                            ارسال پیشنهاد
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    )
}