export const Spinner = (btn) => {
    let submit = document.getElementById(btn);
    // make spinner
    submit.innerHTML = "";
    submit.disabled = true;
    let spinner = document.createElement('span');
    // style of spinner
    spinner.className = "spinner-border";
    spinner.style.color = "#fefefe";
    spinner.style.fontSize = "12px"
    spinner.style.marginTop = "4px"
    submit.appendChild(spinner);
}