// style
import './../css/gallery.css'
// react section
import {Fragment, lazy, useEffect, useState} from "react";
// seo
import {Seo} from "../../../layout/seo/seo";
// banner
import {Banner} from "../../../layout/banner/page/banner";
// route
import {routes} from "../../../../original/route/route";
// cookie
import Cookies from "js-cookie";
// axios
import axios from "axios";
// icon
import {IconX} from "@tabler/icons-react";
// image uploader
import ImageUploading from 'react-images-uploading';
// spinner
import {Spinner} from "../../../../original/js/spinner";
// functions
import {getLike} from "../../../../original/js/like";
// alert
import Swal from "sweetalert2";
// component
const Item = lazy(() => import('./child/item').then(module => ({default: module.Item})));

export const Gallery = () => {
    const [item, setItem] = useState([{}]);
    const [banner, setBanner] = useState('');
    const [userLikeGallery, setUserLike] = useState([{}]);
    const [userName, setUsrName] = useState('')
    // save images
    const [images, setImages] = useState([]);
    // token
    // get user info
    let token = Cookies.get('emarat_token');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const findMaxLikedItem = (items) => {
        const maxLikes = Math.max(...items.map((item) => item.likes));
        return items.filter((item) => item.likes === maxLikes);
    }

    const getGallery = () => {
        axios.get(`${routes[5].address}galleries`)
            .then((response) => {
                let galleries = response.data.data;
                galleries = galleries.filter((item) => item.approved !== "تایید نشده")
                setItem(galleries);
            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }

    useEffect(() => {
        getGallery();
        getLike(setUserLike)
        axios.get(`${routes[5].address}top-galleries`)
            .then((response) => {
                let galleries = response.data.data;
                galleries = galleries.filter((item) => item.approved !== "تایید نشده")
                const maxLikedItems = findMaxLikedItem(galleries);
                setBanner(maxLikedItems[0]?.image);
            })
            .catch((error) => {
                if (error.response) {
                }
            })
        setUsrName(localStorage.getItem('user_name'));
    }, []);
    // limit upload
    const maxNumber = 1;
    const onChange = (imageList) => {
        // data for submit
        setImages(imageList);
    };
    const sendImage = async (btn) => {
        Spinner(btn)
        let submit = document.getElementById(btn)
        let data = new FormData();
        data.append(`name`, document.getElementById('name').value);
        if (images[0]) {
            data.append(`image`, images[0].file);
        }
        await axios.post(`${routes[5].address}gallery`, data, config)
            .then(() => {
                submit.innerHTML = 'تبریک !';
                getGallery();
                getLike();
                document.getElementById('image-alert').innerHTML = null;
                document.getElementById('name-alert').innerHTML = null;
                submit.disabled = false;
                document.getElementById('close-modal').click();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message.image) {
                        document.getElementById('image-alert').innerHTML = error.response.data.message.image;
                    } else {
                        document.getElementById('image-alert').innerHTML = null;
                    }
                    if (error.response.data.message.name) {
                        document.getElementById('name-alert').innerHTML = error.response.data.message.name;
                    } else {
                        document.getElementById('name-alert').innerHTML = null;
                    }
                    if (error.response.data.message === "Unauthenticated.") {
                        submit.innerHTML = 'برای شرکت ابتدا باید در سایت ثبت نام کنید...';
                        document.getElementById('close-modal').click();
                    } else {
                        submit.innerHTML = 'لطفا دوباره تلاش کنید !';
                    }
                    submit.disabled = false;
                }
            })
    }
    const userLike = async (id, setUserLike) => {
        await axios.post(`${routes[5].address}like/${id}`, {}, config)
            .then(() => {
                document.getElementById(`like-gallery${id}`).classList.add('animate');
                getGallery();
                getLike(setUserLike);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message === "شما قبلا این گالری را لایک کرده اید.") {
                        axios.delete(`${routes[5].address}unlike/${id}`, config)
                            .then(() => {
                                getGallery();
                                getLike(setUserLike);
                            })
                            .catch((error) => {
                                if (error.response) {
                                }
                            })
                    }
                    if (error.response.data.message === "Unauthenticated.") {
                        Swal.fire({
                            position: "top-center",
                            icon: "error",
                            background: '#1E1E1E',
                            title: "برای لایک کردن ابتدا باید ثبت نام کنید !",
                            showConfirmButton: true,
                            confirmButtonText: 'تایید',
                            confirmButtonColor: "#C07B36",
                            timer: 3000
                        });
                    }
                }
            })
    }
    return (
        <Fragment>
            <Seo
                title="عمارت سران | گالری شما"
                description="کافه رستوران عمارت سران بهترین انتخاب برای گذراندان اوقات شریفتان در فضایی زیبا به همراه بهترین متریال ها و لذیذ ترین غدا ها که مبنای سعیش جذب رضایت کامل مشتری و عزیزان است !"
                keywords="گالری،لحظات،دلپذیر،شیرین،رقم"
                type="webapp"
                name="progogram"
            />
            <section id="gallery" className="container">
                <Banner image={`${routes[6].address_gallery}${banner}`}
                        title={'گالری شما'}
                        description={
                            `گالری شما ، در اینجا می توانید لحظاتی که در کافه رستوران عمارت سران گذرانده اید را با ما در اشتراک بگذارید
                            و در نتیجه اگر لایک بیشتری به دست بیاورید توسط ما یک سوپرایز هیجان انگیزی را تجربه خواهید کرد`
                        }
                        link={routes[0].address}
                        link_text={'خانه'}/>
                <div id="gallery-page-items" className="mt-5">
                    <div className="text-center">
                        <p className="h2 text-site fw-bold text-center mt-5">عکس های شما</p>
                        <img alt="asset" src={require('./../../../../original/icon/Asset-12 1.png')}
                             className="img-fluid divider mt-2"/>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <button className="btn btn-outline-site" data-bs-toggle="modal"
                                data-bs-target="#gallery-modal">شرکت در گالری
                        </button>
                    </div>
                    <div className="row">
                        {item.map((photo, index) => (
                            <div key={index} className="col-lg-3 mb-4">
                                <Item userLike={() => userLike(photo.id, setUserLike)}
                                      image={photo.image}
                                      like={photo.likes}
                                      time={photo.created_at}
                                      name={photo.name}
                                      id={photo.id}
                                      gallery={item}
                                      likeGallery={userLikeGallery}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal fade" id="gallery-modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-between">
                                <span className="card-title mb-0 h6 fw-bold">شرکت در گالری</span>
                                <IconX id={'close-modal'} color={'#fefefe'} size={16} data-bs-dismiss="modal"/>
                            </div>
                            <div className="modal-body">
                                <div className="form text-center">
                                    <fieldset className="form-floating mt-4">
                                        <input type="text"
                                               defaultValue={userName}
                                               required={true}
                                               id="name"
                                               name="name"
                                               placeholder="نام یا آیدی اینستاگرام"
                                               className="form-control font-14"/>
                                        <label className="form-label font-14">نام یا آیدی اینستاگرام</label>
                                    </fieldset>
                                    <p className="text-site mt-2 font-12 mb-0">
                                        <span id="name-alert"></span>
                                    </p>
                                    <fieldset className="mt-4">
                                        <div className="image" id="page-image">
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                                {({
                                                      imageList,
                                                      onImageUpload,
                                                      onImageUpdate,
                                                      isDragging,
                                                      dragProps,
                                                  }) => (
                                                    // write your building UI
                                                    <div className="upload__image-wrapper">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button
                                                                    className="upload-btn rounded rounded-3"
                                                                    style={isDragging ? {color: 'red'} : undefined}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    برای آپلود کلیک کنید
                                                                </button>
                                                            </div>
                                                            <div className="col-12 mt-3">
                                                                <div
                                                                    className="card img-uploader-card card-body border-0">
                                                                    {imageList.map((image, index) => (
                                                                        <div
                                                                            className="d-flex justify-content-center"
                                                                            key={index}>
                                                                            <div
                                                                                className="image-item">
                                                                                <img
                                                                                    src={image['data_url']}
                                                                                    alt="upload"
                                                                                    id="image-upload-echo"
                                                                                    className="img-fluid image-upload-done mt-3 mb-2"/>
                                                                                <div
                                                                                    className="image-item__btn-wrapper">
                                                                                    <button
                                                                                        className="upload-btn text-center"
                                                                                        onClick={() => onImageUpdate(index)}>
                                                                                        آپدیت
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                    </fieldset>
                                    <p className="text-site mt-2 font-12 mb-0">
                                        <span id="image-alert"></span>
                                    </p>
                                    <button id="send-img" onClick={() => sendImage('send-img')}
                                            className="btn btn-site my-4 w-100">
                                        ارسال عکس
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}